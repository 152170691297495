import {
  Dialog,
  Heading,
  Text,
  TextButton,
  TextLink,
} from '@smart/web-components';
import '@smart/web-components/dist/foundation.css';
import React from 'react';
import countries from './countries';
import './styles.css';

export const App = () => {
  const [modalProps, setModalProps] = React.useState({
    open: false,
    link: ``,
  });
  const sortedCountries = countries.sort((a, b) =>
    a.nation.localeCompare(b.nation),
  );

  void import(`./utils/datadog-rum`).then(({setupDatadogBrowserRum}) => {
    setupDatadogBrowserRum();
  });

  return (
    <div className="light-theme">
      <div className="app">
        <div className="header">
          <img
            className="logo"
            src={`/images/logo.svg`}
            alt="smart-vertical-logo"
          />
        </div>
        <div className="teaser">
          <picture className="teaser-image">
            <source
              srcSet="/teaser_smart_2-75.webp 1440w, /teaser_smart_2-75-small.webp 720w"
              type="image/webp"
            />
            <source
              srcSet="/teaser_smart_2-75.jpg 1440w, /teaser_smart_2-75-small.jpg 720w"
              type="image/jpeg"
            />
            <img src="/teaser_smart_2-75.jpg" alt="teaser" />
          </picture>
        </div>
        <div className="content">
          <Heading className="title" variant="headline-functional-100">
            Select your country or region
          </Heading>

          <p className="description"></p>

          {sortedCountries.map((country) => {
            return (
              <div className="market-container" key={country.nation}>
                <img
                  className="flag"
                  src={`/images/${country.flag}`}
                  alt={country.nation}
                />
                <div className="country">
                  <Heading
                    className="heading-country"
                    variant="headline-functional-300"
                  >
                    {country.nation}
                  </Heading>
                  <div className="languages">
                    {country.languages.map((language) => {
                      if (
                        [
                          `China`,
                          `Iceland`,
                          `Norway`,
                          `Ireland`,
                          `Luxembourg`,
                          `Finland`,
                          `Greece`,
                          `Egypt`,
                          `Chile`,
                          `Lebanon`,
                        ].includes(country.nation)
                      ) {
                        return (
                          <TextButton
                            key={language.title}
                            className="text-link"
                            variant="hyperlink"
                            onClick={() => {
                              setModalProps({
                                open: true,
                                link: language.url,
                              });
                            }}
                          >
                            {language.name}
                          </TextButton>
                        );
                      } else {
                        return (
                          <TextLink
                            key={language.title}
                            className="text-link"
                            variant="hyperlink-100"
                            href={language.url}
                            title={language.title}
                          >
                            {language.name}
                          </TextLink>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="fortwo-container">
            <div className="fortwo-content">
              <Heading
                className="fortwo-heading"
                variant="headline-emotional-200"
              >
                smart EQ fortwo
              </Heading>
              <Text className="fortwo-text" variant="paragraph-100">
                Discover the iconic all-electric two-seater models.
              </Text>
              <TextLink
                variant="button-secondary"
                href="https://uk.smart.com/en/about-us/"
              >
                Find out more.
              </TextLink>
            </div>
            <div className="fortwo-image-container">
              <picture>
                <source srcSet="/fortwo.webp" type="image/webp" />
                <img
                  src="/fortwo.png"
                  alt="smart-fortwo"
                  className="fortwo-image"
                />
              </picture>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer-logo-container">
            <img
              className="logo-text"
              src={`/images/logo-text.svg`}
              alt="smart-text-logo"
            />
          </div>
          <div className="links-container">
            <TextLink
              className="footer-link"
              variant="hyperlink-standalone"
              href="https://media.smart.com/eu"
            >
              Media
            </TextLink>
            <TextLink
              className="footer-link"
              variant="hyperlink-standalone"
              href="https://career.smart.com"
            >
              Careers
            </TextLink>
            <TextLink
              className="footer-link"
              variant="hyperlink-standalone"
              href="https://media.smart.com/eu/imprint-eu1"
            >
              Imprint
            </TextLink>
          </div>
          <div className="divider-container">
            <span className="divider"> </span>
          </div>
          <div className="socials-container">
            <p className="copyright">© 2023 smart Europe GmbH</p>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={modalProps.open}
        outsideClick={() => setModalProps({open: false, link: ``})}
        ariaDescription="Your are now leaving this site"
        text="You are about to leave this site. You will be redirected to an external site. Terms and conditions and privacy policy might differ. Would you really like to leave this page?"
        headline={{tag: `h1`, children: `Your are now leaving this site`}}
        buttons={
          <>
            <div style={{margin: `0.5em`}}>
              <TextButton
                variant="secondary"
                onClick={() => setModalProps({open: false, link: ``})}
              >
                Stay here
              </TextButton>
            </div>
            <div style={{margin: `0.5em`}}>
              <TextLink variant="button-primary" href={modalProps.link}>
                Leave this page
              </TextLink>
            </div>
          </>
        }
      />
    </div>
  );
};
