const envName = process.env.REACT_APP_ENV_NAME || `prod`;

const countries = [
  {
    nation: `Australia`,
    flag: `australia.svg`,
    languages: [
      {
        name: `English`,
        url: createCountryUrl({countryCode: `au`}),
        title: `Smart Australia English`,
      },
    ],
  },
  {
    nation: `Austria`,
    flag: `austria.svg`,
    languages: [
      {
        name: `Deutsch`,
        url: createCountryUrl({countryCode: `at`, language: `de`}),
        title: `Smart Austria German`,
      },
    ],
  },
  {
    nation: `Belgium`,
    flag: `belgium.svg`,
    languages: [
      {
        name: `Français`,
        url: createCountryUrl({countryCode: `be`, language: `fr`}),
        title: `Smart Belgium French`,
      },
      {
        name: `Nederlands`,
        url: createCountryUrl({countryCode: `be`, language: `nl`}),
        title: `Smart Belgium Dutch`,
      },
    ],
  },
  {
    nation: `Chile`,
    flag: `chile.svg`,
    languages: [
      {
        name: `Español`,
        url: `https://www.kaufmann.cl/automoviles/smart/`,
        title: `Smart Chile`,
      },
    ],
  },
  {
    nation: `China`,
    flag: `china.svg`,
    languages: [
      {name: `简体中文`, url: `https://www.smart.cn/`, title: `Smart China`},
    ],
  },
  {
    nation: `Egypt`,
    flag: `egypt.svg`,
    languages: [
      {
        name: `English`,
        url: `https://egypt.smart.com/`,
        title: `Smart Egypt`,
      },
    ],
  },
  {
    nation: `Finland`,
    flag: `finland.svg`,
    languages: [
      {
        name: `Suomi`,
        url: createCountryUrl({countryCode: `fi`, language: `fi`}),
        title: `Smart Finland`,
      },
      {
        name: `Svenska`,
        url: createCountryUrl({countryCode: `fi`, language: `sv`}),
        title: `Smart Finland Swedish`,
      },
    ],
  },
  {
    nation: `France`,
    flag: `france.svg`,
    languages: [
      {
        name: `Français`,
        url: createCountryUrl({countryCode: `fr`, language: `fr`}),
        title: `Smart France`,
      },
    ],
  },
  {
    nation: `Germany`,
    flag: `germany.svg`,
    languages: [
      {
        name: `Deutsch`,
        url: createCountryUrl({countryCode: `de`, language: `de`}),
        title: `Smart Germany`,
      },
    ],
  },
  {
    nation: `Iceland`,
    flag: `iceland.svg`,
    languages: [
      {
        name: `íslenska`,
        url: `https://smart.askja.is/`,
        title: `Smart Iceland`,
      },
    ],
  },
  {
    nation: `Israel`,
    flag: `israel.svg`,
    languages: [
      {
        name: `עִברִית`,
        url: `https://smart.co.il`,
        title: `Smart Israel`,
      },
    ],
  },
  {
    nation: `Ireland`,
    flag: `ireland.svg`,
    languages: [
      {
        name: `English`,
        url: createCountryUrl({countryCode: `ie`, language: `en`}),
        title: `Smart Ireland`,
      },
    ],
  },
  {
    nation: `Italy`,
    flag: `italy.svg`,
    languages: [
      {
        name: `Italiano`,
        url: createCountryUrl({countryCode: `it`, language: `it`}),
        title: `Smart Italy`,
      },
    ],
  },
  {
    nation: `Hong Kong SAR`,
    flag: `hong-kong.svg`,
    languages: [
      {
        name: `English`,
        url: createCountryUrl({countryCode: `hk`, language: `en`}),
        title: `Smart Hong Kong English`,
      },
      {
        name: `繁體中文`,
        url: createCountryUrl({countryCode: `hk`, language: `zh_HK`}),
        title: `Smart Hong Kong Chinese`,
      },
    ],
  },
  {
    nation: `Lebanon`,
    flag: `lebanon.svg`,
    languages: [
      {
        name: `English`,
        url: `https://smart.com.lb`,
        title: `Smart Lebanon`,
      },
    ],
  },
  {
    nation: `Luxembourg`,
    flag: `luxembourg.svg`,
    languages: [
      {
        name: `Français`,
        url: `https://www.smart-luxembourg.lu/fr/landing-page/`,
        title: `Smart Luxembourg French`,
      },
      {
        name: `Deutsch`,
        url: `https://www.smart-luxembourg.lu`,
        title: `Smart Luxembourg German`,
      },
    ],
  },
  {
    nation: `Malaysia`,
    flag: `malaysia.svg`,
    languages: [
      {
        name: `English`,
        url: createCountryUrl({countryCode: `my`, language: ``}),
        title: `Smart Malaysia`,
      },
    ],
  },
  {
    nation: `New Zealand`,
    flag: `new-zealand.svg`,
    languages: [
      {
        name: `English`,
        url: `https://nz.smart.com/`,
        title: `Smart New Zealand`,
      },
    ],
  },
  {
    nation: `Netherlands`,
    flag: `netherlands.svg`,
    languages: [
      {
        name: `Nederlands`,
        url: createCountryUrl({countryCode: `nl`, language: `nl`}),
        title: `Smart Netherlands`,
      },
    ],
  },
  {
    nation: `Portugal`,
    flag: `portugal.svg`,
    languages: [
      {
        name: `Português`,
        url: createCountryUrl({countryCode: `pt`, language: `pt`}),
        title: `Smart Portugal`,
      },
    ],
  },
  {
    nation: `Singapore`,
    flag: `singapore.svg`,
    languages: [
      {
        name: `English`,
        url: `https://sg.smart.com/`,
        title: `Smart Singapore`,
      },
    ],
  },
  {
    nation: `Spain`,
    flag: `spain.svg`,
    languages: [
      {
        name: `Español`,
        url: createCountryUrl({countryCode: `es`, language: `es`}),
        title: `Smart Spain`,
      },
    ],
  },
  {
    nation: `Sweden`,
    flag: `sweden.svg`,
    languages: [
      {
        name: `Svenska`,
        url: createCountryUrl({countryCode: `se`, language: `sv`}),
        title: `Smart Sverige`,
      },
    ],
  },
  {
    nation: `Switzerland`,
    flag: `switzerland.svg`,
    languages: [
      {
        name: `Deutsch`,
        url: createCountryUrl({countryCode: `ch`, language: `de`}),
        title: `Smart Switzerland German`,
      },
      {
        name: `Français`,
        url: createCountryUrl({countryCode: `ch`, language: `fr`}),
        title: `Smart Switzerland French`,
      },
      {
        name: `Italiano`,
        url: createCountryUrl({countryCode: `ch`, language: `it`}),
        title: `Smart Switzerland Italian`,
      },
    ],
  },
  {
    nation: `UAE`,
    flag: `uae.svg`,
    languages: [
      {
        name: `English`,
        url: createCountryUrl({countryCode: `uae`, language: `en`}),
        title: `Smart UAE`,
      },
      {
        name: ` العربية`,
        url: createCountryUrl({countryCode: `uae`, language: `ar`}),
        title: `Smart UAE`,
      },
    ],
  },
  {
    nation: `United Kingdom`,
    flag: `united-kingdom.svg`,
    languages: [
      {
        name: `English`,
        url: createCountryUrl({countryCode: `uk`, language: `en`}),
        title: `Smart United Kingdom`,
      },
    ],
  },
  {
    nation: `Norway`,
    flag: `norway.svg`,
    languages: [
      {
        name: `Norwegian`,
        url: `https://no.smart.com/`,
        title: `Smart Norway`,
      },
    ],
  },
  {
    nation: `Greece`,
    flag: `greece.svg`,
    languages: [
      {
        name: `Ελληνικά`,
        url: `https://www.smartgreece.gr/`,
        title: `Smart Greece`,
      },
      // TODO Add english language once we have the landing page for that
    ],
  },
];

function createCountryUrl({
  countryCode,
  language,
}: {
  countryCode: string;
  language?: string;
}) {
  return `https://${
    envName === `prod` ? `` : `${envName}.`
  }${countryCode}.smart.com/${language ? `${language}/` : ``}`;
}

export default countries;
